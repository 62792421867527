import React, { Component } from 'react';
import axios from 'axios';
import InterstellarSVG from './images/Interstellar.svg';
import { default as vegaEmbed, vega } from 'vega-embed';
import { Button, Row, Col, Tag } from 'antd';

export default class MovieSubExperiment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "vegaSpec": ''
        }
    }

    componentDidMount() {
        this.loadMCU();
        // this.loadDCU();
    }

    loadMovieUniverseSentimentPlots(moviename) {
        let API = axios.create({
            baseURL: 'https://api.sprinkleai.com/sentimentv1'
        });

        return API.get(`/charts?name=${moviename}`);            
    }

    loadMCU() {
        this.loadMovieUniverseSentimentPlots('all_movies_sentiment')
            .then(response => {
                if (response) {
                    let mcuSentimentSpec = response.data;
                    this.setState({
                        "mcuSentimentSpec": mcuSentimentSpec
                    })
                }
            })
            .catch(console.log);        
    }

    loadDCU() {
        this.loadMovieUniverseSentimentPlots('dcu_sentiment')
            .then(response => {
                if (response) {
                    let mcuSentimentSpec = response.data;
                    this.setState({
                        "dcuSentimentSpec": mcuSentimentSpec
                    })
                }
            })
            .catch(console.log);
    }

    loadClassified() {
        let API = axios.create({
            baseURL: 'https://api.sprinkleai.com/sentimentv1'
        });

        API.get("/classified?filename=interstellar")
            .then(response => {
                if (response) {
                    let vegaSpec = response.data;
                    this.setState({
                        "vegaSpec": vegaSpec
                    })
                }
            })
            .catch(console.log);
    }

    handleShowPlot = () => {
        this.loadClassified();
    }
    render() {
        // const interstellar = this.props.images;
        let vegaSpec = this.state.vegaSpec;
        console.log(`render vegaSpec ${vegaSpec}`);
        if (vegaSpec) {
            const spec = {actions: false}
            vegaEmbed('#vis', vegaSpec, spec);
        }

        let mcuSentimentSpec = this.state.mcuSentimentSpec;
        if (mcuSentimentSpec) {
            const spec = {actions: false}
            vegaEmbed('#mcu_chart', mcuSentimentSpec, spec);
        }

        let dcuSentimentSpec = this.state.dcuSentimentSpec;
        if (dcuSentimentSpec) {
            const spec = {actions: false}
            vegaEmbed('#dcu_chart', dcuSentimentSpec, spec);
        }

        return (
            <>
                <h2>Movie Subtitles Sentiment</h2>
                <p>
                    In this experiment, we classify movie subtitles into positive and negative sentiment.
                    We want to find out the performance of our trained model by reviewing subtitle and its predicted sentiment.
                    The sigmoid activation on the final layer outputs a float between 0.0 and 1.0.
                    In our case, predictions closer to 0.0 imply negative sentiment and the ones closer to 1.0 imply positive sentiment.
                    The predictions near 0.5 means that the network is not sure whether to classify negative or positive.
                    We run the classifier on subtitles from the movie Interstellar and generate a line chart.
                    The blue line is the sentiment of each line and orange shows the rolling mean of 10 lines.
                    With this approach, the classifier encounters many lines with very few words such as "Okay Yeah", "Uh hey", "Yes", "No"
                    and usually predict between the range 0.45 and 0.5.
                    The classifier doesn't seem to perform too bad but it does have difficulty classifying short lines.
                </p>
                {/* <Img resolutions={interstellar} /> */}
                <img src={InterstellarSVG} />
                <p>
                    Next, we use an interactive plot to visualise the sentiment.
                    Each vertical bar in the plot corresponds to the sentiment of a line/subtitle.
                    The subtitles have been deliberately removed from the tooltip as I am not sure about the legalities of hosting subtitles.
                    However, you can run the classifier on the movie of your choice and see the subtitle along with its prediction <Tag><a href="http://ts.sprinkleai.com">here</a></Tag>.
                </p>
                <div>
                    <Button icon="barcode" onClick={this.handleShowPlot.bind(this)}>Show interactive plot</Button>
                    <div id="vis">
                    </div>
                </div>
                <p>
                    <h3 style={{marginTop: '10px'}}>Up next, Marvel vs DC.</h3>
                    In the movie Deadpool, Wade Wilson tells Cable “So dark ! You’re sure you’re not from the DC Universe?”
                </p>
                <p style={{ letterSpacing: 2, fontSize: 'medium' }}>Let us ask our neural network.</p>
                <p>
                    We gather subtitles from all the movies that are part of Marvel and DC Cinematic Universe,
                    run our classifier and categorize each subtitle into negative, positive or neutral/undecided.
                    A subtitle is considered positive if its predicted value (sigmoid output) is greater than 0.5,
                    negative if the value is less than 0.4 and neutral if it is between 0.4 and 0.5.
                    As with heuristics, the boundary conditions were chosen based what seemed to work best in my limited tests.
                </p>
                <Row>
                    <Col span={12}>
                        <div id="mcu_chart">
                        </div>
                    </Col>
                    <Col span={12}>
                        <div id="dcu_chart">
                        </div>
                    </Col>
                </Row>
                <p>
                    The Wonder Woman movie seems to contain the most negative sentiment. 
                    The classifier was largely confused by Captain America Winter Soldier 
                    as most of its predictions resulted in the neutral/undecided area.
                    I expected Batman vs Superman to show up as the one with the most negative 
                    sentiment, or was it just a terribly made movie &#129300;
                </p>
                <p>You can play around with the classifier <Tag><a href="https://sent.sprinkleai.com">here</a></Tag>. 
                    All you need is a text file or movie subtitles. The supported file format is *.txt for text files and *.srt for subtitles.</p>
            </>
        )
    }
}