import React from "react"
import { Divider } from 'antd';
import BlogPostChrome from "../../components/BlogPostChrome"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import Poster from './poster.png'
import {Row, Col } from 'antd';
import IMDBMovieSentiment from "./imdbmoviesentiment";
import MovieSubExperiment from './moviesubexperiments';
import CommentsExperiment from './commentsexperiment';
import Layout from './../../components/Layouts';

export const frontmatter = {
    title: `Sentiment analysis using Deep Neural Nets`,
    written: `2018-04-08`,
    updated: `2018-04-12`,
    layoutType: `post`,
    contentType: "blog",    
    path: `/sentiment-v1/`,
    category: `Deep learning`,
    image: './poster.png',
    description: `The movie was well made but story was written poorly`,
}

class GenerativeTextBase extends React.Component {
    constructor(props) {
      super(props)
    }
  
    render() {    
      console.log(this.props.data)  
      const architecturev1 = this.props.data.architecturev1.childImageSharp.resolutions
      const interstellar = this.props.data.interstellar.childImageSharp.resolutions

      return (
        <Layout data={this.props.data} location={this.props.location}>
        <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <h1>Sentiment Analysis experiments</h1>
        <article>
          <p>
            In an earlier <a href="/flash-text-analysis">post</a>, we explored how to 
            use tidy text and QDAP to perform sentiment analysis in R.            
            This post will make use of Convolutional Neural Network (CNN) 
            along with word embeddings to perform sentiment analysis on IMDB movie reviews. 
            Later in the post, we will explore whether the same network can be used to 
            classify sentiment of text from a somewhat different domain (movie subtitles and reddit comments).        
          </p>  
        </article>
        <Divider/>        
        <IMDBMovieSentiment images={architecturev1}/>
        <Divider/>        
        <MovieSubExperiment images={interstellar}/>
        <Divider/>        
        <CommentsExperiment />
        <Divider/>    
        <h2>Conclusion</h2>
        <p>
          We trained a Neural network on IMDB movie reviews and tested it on text from different domain.
          Apart from domain, the input size was to different too.  
          The network was trained to classify review of 500 letters as postive or negative whereas in our testing 
          we used it on sentence level classification. 
                    
          We saw that it did not perform well on reddit comments.
          It also performed poorly on sarcastic comments, perhaps the reviews weren't sarcastic enough.
          More than often, the predictions were between 0.4 and 0.5 which indicate that network was not 
          very certain about the sentiment.           
        </p>
        <p>
          My goal with these experiments was to find out how far we can go with the IMDB model.
          Not very far, it seems to work on sentences such as "I like simple things in life" 
          but fails to classify "These candies are delicious" correctly. 
          It will be interesting to train a classifier on tweets and compare its performance with the IMDB model.
          And yes I do agree with what you are thinking, we could improve the classifier by generating domain specific training data.
        </p>
        <p>
          The question then is, whether we can use transfer learning in NLP tasks similar to computer vision, 
          where we train a base model on imagenet and fine tune on task specific datasets.          
          What would be the imagenet equivalent for NLP ? 
          This is an active area of research and new papers such as Deep contextualized word representations show us one of the ways forward.
        </p>
        </BlogPostChrome>
        </Layout>
      )
    }
  }
  
  export default GenerativeTextBase
  
  const styles = {}

  styles.row = {
    display: `flex`,
    flexWrap: `wrap`,
    margin: `8px -4px 1rem`,
  }

  // We want to keep this component mostly about the code
  //  so we write our explanation with markdown and manually pull it in here.
  //  Within the config, we loop all of the markdown and createPages. However,
  //  it will ignore any files appended with an _underscore. We can still manually
  //  query for it here, and get the transformed html though because remark transforms
  //  any markdown based node.
  export const pageQuery = graphql`
    query sentimentv1query($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2018-02-14-sentiment-v1/_gentext/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      architecturev1: file(
        relativePath: {regex: "/sentiment_v1_arch.png/"}) {
        childImageSharp {
          resolutions(width: 119, height: 300) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      interstellar: file(
        relativePath: {regex: "/Interstellar.png/"}) {
        childImageSharp {
          resolutions(width: 1024, height: 475) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
    }
  `
  