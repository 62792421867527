import React, { Component } from 'react';

const comments_data = [
    {
        id: 1, 
        subreddit: 'worldnews',
        reddit_score: -13,
        comment: [
            {
                line: "Guilty as charged.",
                pred: 0.52
            },
            {
                line: "I just had a feeling that if I didn't do that reddit would take this as a pro-war story and.",
                pred: 0.43
            },
            {
                line: "well, down vote it to hell",
                pred: 0.62
            },

        ]
    },
    {
        id: 2, 
        subreddit: 'news',
        reddit_score: -45,
        comment: [
            {
                line: "WWIII was the cold war.",
                pred: 0.49
            },
            {
                line: "Get a basic grip on history",
                pred: 0.74
            },
        ]
    },
    {
        id: 3, 
        subreddit: 'xkcd',
        reddit_score: -17,
        comment: [
            {
                line: "When you do an exact copy of something and change absolutely nothing but the name, how is that not stealing?.",
                pred: 0.23
            },
            {
                line: "This is not a variation on the theme of graph-based comic.",
                pred: 0.43
            },
            {
                line: "It's straight up plagiarism",
                pred: 0.35
            },

        ]
    },
    {
        id: 4, 
        subreddit: 'xkcd',
        reddit_score: 16,
        comment: [
            {
                line: "Agreed.",
                pred: 0.43
            },
            {
                line: "Nerd rage can be quite powerful.",
                pred: 0.46
            },
            {
                line: "Almost had to put my velociraptor t-shirt on for emphasis",
                pred: 0.46
            },

        ]
    },
    {
        id: 5, 
        subreddit: 'gaming',
        reddit_score: 19,
        comment: [
            {
                line: "Shhh, shhh, quiet for a second.",
                pred: 0.74
            },
            {
                line: "Listen.",
                pred: 0.43
            },
            {
                line: "You hear that? That very faint noise? That was the sound of a joke flying over your head",
                pred: 0.39
            },

        ]
    },
    {
        id: 6, 
        subreddit: 'science',
        reddit_score: 14,
        comment: [
            {
                line: "But I still do not understand how do the GPS satellites know their location! They do not triangulate themselves with respect to quasars, do they?.",
                pred: 0.21
            },
            {
                line: "Any GPS engineer to rescue? Thanks!",
                pred: 0.30
            }
        ]
    },
    {
        id: 7, 
        subreddit: 'science',
        reddit_score: 19,
        comment: [
            {
                line: "according to the comments on youtube: Water is diamagnetic and frogs are mostly water.",
                pred: 0.24
            },
            {
                line: "Diamagnetic compounds have a weak repulsive reaction to magnetic fields that is overpowered by the presence of paramagnetic or ferromagnetic fields.",
                pred: 0.09
            },
            {
                line: "Look if up, if you doubt me.",
                pred: 0.43
            },
            {
                line: "So therefore, imagine all the water in you being pushed up while the rest of you still has gravity pulling it down.",
                pred: 0.76
            }
        ]
    }
]

let isNeutral = (pred) => {
    return (pred > 0.4 && pred <= 0.5) ? true : false;
}

let scale = (pred, min , max) => {
    let value = (pred - min)/(max - min);
    value = value <= 0 ? 0.01 : value;
    return value;
}

let sentimentColor = (pred) => {
    let color;
    if (isNeutral(pred)) {
        color = `rgba(180,180,180,${scale(pred, 0.4, 0.5)})`;
    } else {
        color = pred < 0.5 ? `rgba(228,55,16,${scale((1.0 - pred), 0.6, 1.0)})` : `rgba(44,179,84,${scale(pred, 0.5, 1.0)})`;
    }

    return {
        'background': color,
        'border-color': 'rgb(228,55,16)'
    }
}

let showComment = (comment) => {
    let element = Object.keys(comment).map(key => {        
        let line = comment[key];
        return (
            <mark key={key} style={sentimentColor(line.pred)}>
                {line.line}
            </mark>
        )
    });

    return element;
}

const Comments = props => {        
     let elements = Object.keys(props.data).map(key => {
        let comment = props.data[key];
        return <div className="box">
            <p class="heading">subreddit: {comment.subreddit} | reddit score: {comment.reddit_score}</p>
            {showComment(comment.comment)}
        </div>
    });

    return <div>{elements}</div>;
}

export default class CommentsExperiement extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <h2>Reddit Comments Sentiment</h2>
                <p>
                    We use the same classifier, that was trained on IMDB movie reviews, and run it on comments taken from Reddit <a href="https://files.pushshift.io/reddit/comments/">dataset</a>.                    
                    The comments below are color-coded with green as positive, gray as neutral/undecided and red as negative.The model seems reasonable in identifying negative comments but fails to detect sarcasm.

                </p>
                <i>Disclaimer: I do not support or endorse any of the following comments made on Reddit and my only intention is to use them as an example to test classifier.</i>
                <Comments data={comments_data} />
                <p>Okay, the last two comments left the classfier clueless, and me too.</p>
            </>
        )
    }
}