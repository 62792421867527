import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Row, Col } from 'antd';

export default class IMDBMovieSentiment extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const architecturev1 = this.props.images;
        return (
            <>
                <h2>IMDB Movie Sentiment</h2>
                <div>
                    <p>
                    We make use of IMDB movie review <a href="http://ai.stanford.edu/~amaas/data/sentiment/">dataset</a> which contains 25K reviews for training and 25K for the test.                    
                    The overall process is broken into three categories: Preprocessing, Neural Network Architecture and Performance.
                    We start with preprocessing step that converts the data into a format that our Neural Network can understand and work with.
                    Then, we search for a Neural network architecture that performs well on the test set.                    
                    </p>
                    <Row>
                        <Col span={20}>
                            <div>
                            <p>
                                The preprocessing steps include
                            </p>
                                <ol>
                                    <li>Clean up: remove unwanted characters such as exclamation, hyphen, open and close brackets, angular brackets etc</li>
                                    <li>Build a vocabulary of words and drop less frequent words</li>
                                    <li>Convert word into integer representation, this is required because Neural Networks work on numbers</li>
                                    <li>Convert each review into fixed length vector</li>
                                </ol>
                            <p>
                            Once we have the preprocessed data, we begin experimenting with network architecture and choose the one that performs well on both train and test set.
                            The architecture shown here worked well in my tests.
                            My jupyter notebook covers other architectures as well.
                            </p>    
                            <p>
                                Overall, our network gained from two main choices: Word Embeddings and Dropout. 
                                By now, we have a trained model ready for our experiments. Let's play.
                            </p>
                            </div>                 
                        </Col>   
                        <Col span={4}>
                        <Img resolutions={architecturev1} />                       
                        </Col>
                    </Row>                    
                </div>               
            </>
        )
    }
}